import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';
import { createPersonAPI, deletePersonAPI, getPersonListAPI, updatePersonAPI } from 'APIs/person';

// ==============================|| REDUX STORE: PERSON DATA ||============================== //

const GET_PERSON_LIST = createAsyncThunk('person/list', async () => {
    console.log('calling GET_PERSON_LIST');
    const response = await getPersonListAPI();
    return response;
});

const UPDATE_PERSON = createAsyncThunk('person/update', async ({ id, data = {}, file }) => {
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'personPhoto', mimeType: file.type });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update Person data
    const response = await updatePersonAPI({ id, data });
    return response;
});

const DELETE_PERSON = createAsyncThunk('person/delete', async ({ id }) => {
    if (!id) {
        throw new Error('Missing id');
    }
    const response = await deletePersonAPI({ id });
    return response;
});

const CREATE_PERSON = createAsyncThunk('person/create', async ({ data }) => {
    const response = await createPersonAPI({ data });
    return response;
});

const initialState = {
    initialized: false,
    persons: []
};

const personSlice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        create(state, action) {
            state.persons.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.persons = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_PERSON.fulfilled, (state, action) => {
                if (action.payload) {
                    state.persons.push(action.payload);
                }
            })
            .addCase(DELETE_PERSON.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.persons = state.persons.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(GET_PERSON_LIST.fulfilled, (state, action) => {
                state.persons = [...action.payload];
                state.initialized = true;
            });
    }
});

const { create, logout } = personSlice.actions;
const initialized = (state) => state?.person?.initialized;
const persons = (state) => state?.person?.persons;

export { create, CREATE_PERSON, DELETE_PERSON, GET_PERSON_LIST, initialized, logout, persons, UPDATE_PERSON };
export default personSlice.reducer;

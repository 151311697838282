import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const createAssetAPI = async ({ data }) => {
    // debugger; // eslint-disable-line no-debugger
    const response = await apiPost({ url: `/asset`, data });
    return response;
};
const getAssetListAPI = async () => {
    const response = await apiGet({ url: `/asset/list` });
    return response;
};

const registerAssetAPI = async ({ id }) => apiGet({ url: `/asset/register?id=${id}` });

export { createAssetAPI, getAssetListAPI, registerAssetAPI };

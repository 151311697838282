import admin from './admin';
import member from './member';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = ({ userType }) => {
    const items = [];

    if (userType === 'admin') {
        items.push(admin);
    } else {
        items.push(member);
    }
    return { items };
};

export default menuItems;

import dayjs from 'dayjs';

// material-ui
import { Chip } from '@mui/material';

// Project Imports
import { assetStatus } from 'config';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const getRowID = (row) => {
    let rowID = parseInt(row, 10);
    rowID += 1;
    return rowID.toString();
};

const getUserType = (userType) => {
    if (!userType) {
        return 'Unknown';
    }
    return userType.charAt(0).toUpperCase() + userType.toLowerCase().slice(1);
};

const formatCurrency = (amount) => {
    if (!amount) return null;
    const ret = formatter.format(amount);
    return ret;
};

const formatCurrencyString = ({ amount, currency }) => {
    if (!amount || !currency) return null;
    let currencySymbol = 'US$';
    if (currency.toLowerCase() === 'gbp') {
        currencySymbol = '£';
    }
    if (!amount) {
        return `${currencySymbol}0.00`;
    }
    // const amountInt = parseInt(amount,10);
    const displayAmount = (Math.round(amount) / 100).toFixed(2);
    return `${currencySymbol}${displayAmount.toLocaleString()}`;
};

const formatPercentage = ({ amount }) => {
    if (!amount) return null;
    return `${amount} %`;
};

const formatName = (row) => {
    if (!row) return 'Unknown';
    if (row.name) return row.name;
    if (row.fullName) return row.fullName;
    if (row.givenName && row.familyName) return `${row.givenName} ${row.familyName}`;
    if (row.givenName) return row.givenName;
    if (row.familyName) return row.familyName;
    return 'Unknown';
};

const getAssetStatus = (rowStatus) => {
    // debugger; // eslint-disable-line no-debugger
    const { color = 'error', name = 'Error' } = assetStatus.find((item) => item.id === rowStatus) || {};
    return <Chip color={color} label={name} size="small" />;
};

const getJSDate = (dbDate) => {
    if (!dbDate) return null;
    return dayjs(dbDate);
};

export { getAssetStatus, getRowID, getUserType, formatCurrency, formatName, formatPercentage, formatCurrencyString, getJSDate };

import axios from 'axios';
// import { API_BASE_URL } from 'config';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_URL_PUBLIC = `${API_BASE_URL}/public`;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        accept: 'application/json'
    }
});

const apiPublicClient = axios.create({
    baseURL: API_BASE_URL_PUBLIC,
    headers: {
        accept: 'application/json'
    }
});

const apiGet = async ({ url }) => {
    console.log(`apiGet>>>> ${url}`);
    const response = await apiClient.get(url);
    console.log(response);
    return response.data;
};

const apiPost = async ({ url, data }) => {
    console.log('apiPost>>>>', url);
    const response = await apiClient.post(url, data);
    console.log(response);
    return response.data;
};

const apiUpdate = async ({ url, data }) => {
    console.log('apiPatch>>>>');
    const response = await apiClient.patch(url, data);
    console.log(response);
    return response.data;
};

const apiDelete = async ({ url }) => {
    console.log('apiDelete>>>>', url);
    const response = await apiClient.delete(url);
    console.log(response);
    return response.data;
};

const apiFileUpload = async ({ url, file }) => {
    console.log('apiFileUpload>>>>');
    const headers = { 'Content-Type': file.type };
    const response = await axios.put(url, file, { headers });
    console.log(response);
    return response.data;
};

const apiPublicGet = async ({ url }) => {
    console.log(`apiPublicGet>>>> ${url}`);
    const response = await apiPublicClient.get(url);
    console.log(response);
    return response.data;
};

const apiPublicUpdate = async ({ url, data }) => {
    console.log('apiPublicPatch>>>>');
    const response = await apiPublicClient.patch(url, data);
    console.log(response);
    return response.data;
};

export default apiClient;
export { apiGet, apiUpdate, apiPost, apiDelete, apiFileUpload, apiPublicGet, apiPublicUpdate };

// third-party
import { configureStore } from '@reduxjs/toolkit';
import { loadState } from './persistState';
import accountReducer from './slices/accountSlice';
import assetReducer from './slices/assetSlice';
import menuReducer from './slices/menu';
import personSlice from './slices/personSlice';
import postReducer from './slices/postSlice';
import statusReducer from './slices/statusSlice';
import userReducer from './slices/userSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistedState = loadState();

const store = configureStore({
    reducer: {
        menu: menuReducer,
        status: statusReducer,
        user: userReducer,
        account: accountReducer,
        asset: assetReducer,
        post: postReducer,
        person: personSlice
    },
    preloadedState: persistedState
});

export default store;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPostListAPI, createPostAPI, updatePostAPI } from 'APIs/post';

// ==============================|| REDUX STORE: POST DATA ||============================== //

const CREATE_POST = createAsyncThunk('post/create', async ({ data }) => {
    // Create Post Record
    const response = await createPostAPI({ data: { ...data } });
    return response;
});

const GET_POST_LIST = createAsyncThunk('post/list', async () => {
    const response = await getPostListAPI();
    return response;
});

const UPDATE_POST = createAsyncThunk('post/update', async ({ id, data }) => {
    const response = await updatePostAPI({ id, data });
    return response;
});

const initialState = {
    initialized: false,
    posts: []
};

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        create(state, action) {
            state.posts.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.posts = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_POST.fulfilled, (state, action) => {
                state.posts.push(action.payload);
            })
            .addCase(UPDATE_POST.fulfilled, (state, action) => {
                state.posts = state.posts.map((post) => {
                    if (post.id === action.payload.id) {
                        return { ...post, ...action.payload };
                    }
                    return post;
                });
            })
            .addCase(GET_POST_LIST.fulfilled, (state, action) => {
                state.posts = [...action.payload];
                state.initialized = true;
            });
    }
});

const { logout } = postSlice.actions;
const initialized = (state) => state?.post?.initialized;
const posts = (state) => state?.post?.posts || [];

export { CREATE_POST, GET_POST_LIST, UPDATE_POST, initialized, posts, logout };
export default postSlice.reducer;

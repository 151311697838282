import { apiGet, apiFileUpload } from './apiClient';

// Org's bucket directory will be determined from users OrdID
const getFileURLAPI = async ({ fileName, path, mimeType, fileRef, isPrivate = true }) => {
    const url = `/file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}&isPrivate=${isPrivate}`;
    const response = await apiGet({ url });
    return response;
};

// Needed as Admins need to post to any Org's bucket directory
const adminGetFileURLAPI = async ({ fileName, path, mimeType, fileRef, isPrivate }) => {
    const url = `/admin/file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}&isPrivate=${isPrivate}`;
    const response = await apiGet({ url });
    return response;
};

const getFileDownloadURLAPI = async (location) => {
    const url = `/file/geturl/download?url=${location}`;
    const response = await apiGet({ url });
    return response;
};

const uploadFileWithURLAPI = async ({ url, file }) => {
    const uploadResponse = await apiFileUpload({ url, file });
    console.log('Upload File:', uploadResponse);
};

export { getFileURLAPI, uploadFileWithURLAPI, adminGetFileURLAPI, getFileDownloadURLAPI };

import { lazy } from 'react';

// project imports
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import PublicLayout from 'layout/PublicLayout';
import SamplePage from 'views/sample-page';

// login routing
const MemberUpdatePage = Loadable(lazy(() => import('views/members/PublicMemberUpdate')));

// ==============================|| PUBLIC ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <PublicLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/public/member/:personID',
            element: <MemberUpdatePage />
        },
        {
            path: '/public/callback',
            element: <SamplePage />
        }
    ]
};

export default PublicRoutes;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconFileText, IconHelp } from '@tabler/icons';

// ===========================|| ENTRANT MENU OPTIONS ||=========================== //

const member = {
    id: 'member-menu',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/member',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'investments-page',
            title: <FormattedMessage id="investments-page" />,
            type: 'item',
            url: '/member/investments',
            icon: IconFileText,
            breadcrumbs: false
        },
        {
            id: 'sample-page2',
            title: 'Play Area',
            type: 'item',
            url: '/member/play',
            icon: IconHelp,
            breadcrumbs: false
        }
    ]
};

export default member;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';

// Pages
const AdminDashboard = Loadable(lazy(() => import('views/dashboard/AdminDashboard')));
const MemberList = Loadable(lazy(() => import('views/members')));
const MemberDetail = Loadable(lazy(() => import('views/members/MemberDetail')));
const CompanyList = Loadable(lazy(() => import('views/sample-page')));
const CompanyDetail = Loadable(lazy(() => import('views/sample-page')));
const InvestmentList = Loadable(lazy(() => import('views/sample-page')));
const InvestmentDetail = Loadable(lazy(() => import('views/sample-page')));
const EventList = Loadable(lazy(() => import('views/sample-page')));
const EventDetail = Loadable(lazy(() => import('views/sample-page')));
const FundList = Loadable(lazy(() => import('views/sample-page')));
const FundDetail = Loadable(lazy(() => import('views/sample-page')));
const UserList = Loadable(lazy(() => import('views/users')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));
const LinkedInPage = Loadable(lazy(() => import('views/linkedIn')));
const LinkedInAssets = Loadable(lazy(() => import('views/linkedIn/assets/Assets')));
const LinkedInPosts = Loadable(lazy(() => import('views/linkedIn/posts/Posts')));
const LinkedInCalendar = Loadable(lazy(() => import('views/linkedIn/calendar/Calendar')));
const LinkedInCallback = Loadable(lazy(() => import('views/linkedIn/LinkedinOAuth')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminDashboard />
        },
        {
            path: '/members',
            element: <MemberList />
        },
        {
            path: '/member',
            element: <MemberDetail />,
            children: [{ path: ':id', element: <MemberDetail /> }]
        },
        {
            path: '/companies',
            element: <CompanyList />
        },
        {
            path: '/company',
            element: <CompanyDetail />,
            children: [{ path: ':id', element: <CompanyDetail /> }]
        },
        {
            path: '/investments',
            element: <InvestmentList />
        },
        {
            path: '/investment',
            element: <InvestmentDetail />,
            children: [{ path: ':id', element: <InvestmentDetail /> }]
        },
        {
            path: '/events',
            element: <EventList />
        },
        {
            path: '/event',
            element: <EventDetail />,
            children: [{ path: ':id', element: <EventDetail /> }]
        },
        {
            path: '/funds',
            element: <FundList />
        },
        {
            path: '/fund',
            element: <FundDetail />,
            children: [{ path: ':id', element: <FundDetail /> }]
        },
        {
            path: '/users',
            element: <UserList />
        },
        {
            path: '/user',
            element: <UserDetail />,
            children: [{ path: ':id', element: <UserDetail /> }]
        },
        {
            path: '/page2',
            element: <PlayPage />
        },
        {
            path: '/linkedin',
            element: <LinkedInPage />
        },
        {
            path: '/linkedin/assets',
            element: <LinkedInAssets />
        },
        {
            path: '/linkedin/posts',
            element: <LinkedInPosts />
        },
        {
            path: '/linkedin/calendar',
            element: <LinkedInCalendar />
        },
        {
            path: '/linkedin/callback',
            element: <LinkedInCallback />
        },
        {
            path: 'admin/settings',
            element: <UserSettings />
        }
    ]
};

export default MainRoutes;

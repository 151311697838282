import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { promisify } from 'util';

// project imports
import { LOGIN, LOGOUT } from 'store/slices/accountSlice';
import { USER_MESSAGE } from 'store/slices/statusSlice';
import { logout as menuLogout } from 'store/slices/menu';
import { logout as usersLogout } from 'store/slices/userSlice';
import apiClient from 'APIs/apiClient';

export const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_COGNITO_APP_ID || ''
});

const getUserType = (groups = []) => {
    if (groups.includes('admin')) {
        return 'admin';
    }
    return 'member';
};
const formatSession = (sessionData) => {
    // debugger; // eslint-disable-line no-debugger
    const userData = sessionData.idToken.payload;
    const user = {};
    if (userData['cognito:username']) user.userName = userData['cognito:username'];
    if (userData.email) user.email = userData.email;
    if (userData['custom:personID']) user.personID = userData['custom:personID'];
    if (userData.name) user.name = userData.name;
    if (userData.given_name) user.givenName = userData.given_name;
    if (userData.family_name) user.familyName = userData.family_name;
    // TODO: why???
    user.type = getUserType(userData['cognito:groups']);

    const session = {
        credentials: {
            accessToken: sessionData.accessToken.jwtToken,
            idToken: sessionData.idToken.jwtToken,
            refreshToken: sessionData.refreshToken.token
        },
        user
    };
    return session;
};

const setAPIToken = (jwtToken) => {
    if (jwtToken) {
        apiClient.defaults.headers.common.Authorization = jwtToken;
    } else {
        delete apiClient.defaults.headers.common.Authorization;
    }
};

// ==============================|| AWS COGNITO CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state?.account?.isLoggedIn);
    const userType = useSelector((state) => state?.account?.user?.type) || 'member';
    let savedUserAttributes;
    let savedCognitoUser;
    // const [savedUserAttributes, setSavedUserAttributes] = useState();

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            loggedInUser.signOut();
        }
        setAPIToken();
        dispatch(LOGOUT());
        dispatch(menuLogout());
        dispatch(usersLogout());
    };

    // Gets a new Cognito session. Returns a promise.
    const getSession = () =>
        new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (!cognitoUser) {
                reject(new Error(`No current user`));
                return;
            }
            try {
                cognitoUser.getSession((err, result) => {
                    if (err || !result) {
                        reject(new Error(`Failure getting Cognito session: ${err}`));
                    }
                    resolve(result);
                });
            } catch (error) {
                console.log(error);
            }
        });

    // Returns a valid JWT Token, the token will be refreshed automatically if needed
    const getToken = async () => {
        try {
            const session = await getSession();
            return session.getIdToken().getJwtToken();
        } catch (error) {
            // There was an issue => Logout
            console.log(error.message);
            logout();
        }
        return undefined;
    };

    const axiosRequestInterceptor = () => {
        apiClient.interceptors.request.use(
            async (config) => {
                const token = await getToken();
                config.headers.Authorization = token;
                return config;
            },
            (error) => {
                // Error
                console.log(error);
                return Promise.reject(error);
            }
        );
    };

    const axiosResponseInterceptor = () => {
        apiClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response.status === 401) {
                    console.log('INTERCEPTOR >>>401');
                    if (!originalRequest.retry) {
                        originalRequest.retry = true;
                        // Will need to do a refresh
                        const newToken = await getToken();
                        originalRequest.headers.Authorization = newToken;
                        setAPIToken(newToken);
                        return apiClient(originalRequest);
                    }
                    // Tried before - no luck - goodbye!
                    logout();
                    return null;
                }
                // TODO - other non 200 errors
                console.log('>>>return error: ', error.response?.data?.errorMessage);
                console.log('>>>status: ', error.response?.status);
                const errorMessage = error.response?.data?.errorMessage || 'ERROR: Undefined';
                // const errorMessage = `There was a problem with this request: ${error.response?.data?.errorMessage}`;
                dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
                throw error;
            }
        );
    };

    useEffect(() => {
        const init = async () => {
            console.log('AWSCognitoContext - useEffect');
            try {
                const token = await getToken();
                setAPIToken(token);
                axiosResponseInterceptor();
                axiosRequestInterceptor();
                const loggedInUser = userPool.getCurrentUser();
                if (loggedInUser) {
                    const session = await getSession();
                    // Login with user data
                    const { user } = session || {};
                    dispatch(LOGIN(user));
                } else {
                    logout();
                }
            } catch (err) {
                console.error(err);
                logout();
            }
        };
        init();
    }, []);

    // Check to see if a forced password change is in progress
    const newPasswordInProgress = useMemo(() => {
        // debugger; // eslint-disable-line no-debugger
        const inProgress = !!savedCognitoUser && !!savedUserAttributes;
        return inProgress;
    }, [savedCognitoUser, savedUserAttributes]);

    const contextValue = useMemo(() => {
        // Login
        const login = (email, password) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });
            const authData = new AuthenticationDetails({
                Username: email,
                Password: password
            });
            return new Promise((resolve, reject) => {
                console.log('authenticating user');
                usr.authenticateUser(authData, {
                    onSuccess: (session) => {
                        // Login with user data
                        const { user } = formatSession(session) || {};
                        dispatch(LOGIN(user));
                        // Update API Token
                        setAPIToken(session.getIdToken().getJwtToken());
                    },
                    onFailure: reject,
                    newPasswordRequired: (userAttributes) => {
                        // console.log('here>>>');
                        // console.log(userAttributes);
                        delete userAttributes.email_verified;
                        delete userAttributes.email;
                        savedUserAttributes = userAttributes;
                        savedCognitoUser = usr;
                        console.log('savedUserAttributes: ', savedUserAttributes);
                        // setSavedUserAttributes(userAttributes);
                        const responseError = new Error('A new password is required');
                        responseError.type = 'newPasswordRequired';
                        throw responseError;
                    }
                });
            });
        };

        // New Password
        const newPassword = async (newPassword) => {
            console.log('here');
            return new Promise((resolve, reject) => {
                savedCognitoUser.completeNewPasswordChallenge(newPassword, savedUserAttributes, {
                    onSuccess: resolve,
                    onFailure: reject
                });
            });
        };
        // Password Reset
        const resetPassword = (email, code, newPassword) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });
            return new Promise((resolve, reject) => {
                usr.confirmPassword(code, newPassword, {
                    onSuccess: resolve,
                    onFailure: reject
                });
            });
        };
        // Forgotten password
        const forgotPassword = (email) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });
            return new Promise((resolve, reject) => {
                usr.forgotPassword({
                    onSuccess: resolve,
                    onFailure: reject
                });
            });
        };
        // Resend Code
        const resendCode = (email) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });
            const promisifiedResend = promisify(usr.resendConfirmationCode).bind(usr);
            return promisifiedResend();
        };
        // Confirm Registration
        const confirmRegistration = (email, code) => {
            const usr = new CognitoUser({
                Username: email,
                Pool: userPool
            });
            const promisifiedVerify = promisify(usr.confirmRegistration).bind(usr);
            return promisifiedVerify(code, true);
        };
        // Register User
        const register = (email, password, firstName, lastName) => {
            const emailAttribute = new CognitoUserAttribute({
                Name: 'email',
                Value: email
            });
            const nameAttribute = new CognitoUserAttribute({
                Name: 'name',
                Value: `${firstName} ${lastName}`
            });
            const givenNameAttribute = new CognitoUserAttribute({
                Name: 'given_name',
                Value: `${firstName}`
            });
            const familyNameAttribute = new CognitoUserAttribute({
                Name: 'family_name',
                Value: `${lastName}`
            });
            const attributes = [emailAttribute, nameAttribute, givenNameAttribute, familyNameAttribute];
            const promisifiedSignUp = promisify(userPool.signUp).bind(userPool);

            return promisifiedSignUp(email, password, attributes, null);
        };

        // Check Token
        const checkToken = async () => {
            console.log('Checking Token');
            const session = await getSession();
            const expiry = session.getIdToken().getExpiration();
            console.log(`Expiry: ${expiry}`);
            const now = Math.floor(new Date() / 1000);
            console.log(`Now: ${now}`);
            console.log(`Difference: ${expiry - now}`);
        };

        return {
            isLoggedIn,
            userType,
            login,
            logout,
            register,
            confirmRegistration,
            resendCode,
            forgotPassword,
            resetPassword,
            newPassword,
            getSession,
            getToken,
            checkToken,
            newPasswordInProgress
        };
    }, [newPasswordInProgress, savedUserAttributes, savedCognitoUser, isLoggedIn, userType]);

    return <AWSCognitoContext.Provider value={contextValue}>{children}</AWSCognitoContext.Provider>;
};

AWSCognitoProvider.propTypes = {
    children: PropTypes.node
};

export default AWSCognitoContext;

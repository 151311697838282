// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconAward,
    IconBrandLinkedin,
    IconCoinPound,
    IconDashboard,
    IconFileDollar,
    IconFileText,
    IconHelp,
    IconTrophy,
    IconFileCertificate,
    IconListCheck,
    IconCertificate,
    IconUserCheck,
    IconUsers,
    IconHome,
    IconGauge,
    IconPhoto,
    IconCalendar,
    IconNews
} from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'member-page',
            title: <FormattedMessage id="member-page" />,
            type: 'item',
            url: '/members',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'companies-page',
            title: <FormattedMessage id="companies-page" />,
            type: 'item',
            url: '/company',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'investments-page',
            title: <FormattedMessage id="investments-page" />,
            type: 'item',
            url: '/investments',
            icon: IconFileDollar,
            breadcrumbs: false
        },
        {
            id: 'events-page',
            title: <FormattedMessage id="events-page" />,
            type: 'item',
            url: '/events',
            icon: IconFileDollar,
            breadcrumbs: false
        },
        {
            id: 'users-page',
            title: <FormattedMessage id="users-page" />,
            type: 'item',
            url: '/users',
            icon: IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'linkedin',
            title: <FormattedMessage id="linkedin" />,
            type: 'collapse',
            icon: IconBrandLinkedin,
            children: [
                {
                    id: 'linkedin-home',
                    title: <FormattedMessage id="linkedin-home" />,
                    type: 'item',
                    url: '/linkedin',
                    icon: IconGauge,
                    breadcrumbs: false
                },
                {
                    id: 'linkedin-assets',
                    title: <FormattedMessage id="linkedin-assets" />,
                    type: 'item',
                    url: '/linkedin/assets',
                    icon: IconPhoto,
                    breadcrumbs: false
                },
                {
                    id: 'linkedin-posts',
                    title: <FormattedMessage id="linkedin-posts" />,
                    type: 'item',
                    url: '/linkedin/posts',
                    icon: IconNews,
                    breadcrumbs: false
                },
                {
                    id: 'linkedin-calendar',
                    title: <FormattedMessage id="linkedin-calendar" />,
                    type: 'item',
                    url: '/linkedin/calendar',
                    icon: IconCalendar,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'sample-page2',
            title: 'Play Area',
            type: 'item',
            url: '/page2',
            icon: IconHelp,
            breadcrumbs: false
        }
    ]
};

export default admin;

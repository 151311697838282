import { Outlet } from 'react-router-dom';
import AuthWrapper1 from 'views/authentication/auth-wrappers/AuthWrapper1';

// ==============================|| PUBLIC LAYOUT ||============================== //

const PublicLayout = () => (
    <AuthWrapper1>
        <Outlet />
    </AuthWrapper1>
);

export default PublicLayout;

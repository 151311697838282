// material-ui
import { Typography, Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const SamplePage = () => (
    <Grid container direction="column" sx={{ minHeight: '100vh', px: 2, py: 2 }}>
        <Grid item xs={12}>
            <MainCard title="Sample Card">
                <Typography variant="body2">
                    Lorem ipsum dolor sit amen, consenter nipissing eli, sed do elusion tempos incident ut laborers et doolie magna alissa.
                </Typography>
            </MainCard>
        </Grid>
    </Grid>
);

export default SamplePage;

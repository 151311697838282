import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAPI, updateUserAPI } from 'APIs/user';
import {
    accountLoginAPI,
    addPaymentMethodAPI,
    getInvoiceListAPI,
    getPricesAPI,
    updateUserPasswordAPI,
    authUserLinkedinAPI
} from 'APIs/account';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    isInitialized: false,
    invoicesInitialized: false,
    pricesInitialized: false,
    invoices: [],
    user: {},
    org: {},
    prices: {}
};

// ==============================|| REDUX STORE: ACCOUNT DATA ||============================== //

// Called after login to initilize user and org data
const ACCOUNT_LOGIN = createAsyncThunk('account/login', async () => {
    // debugger; // eslint-disable-line no-debugger
    console.log('accountLogin');
    const response = await accountLoginAPI();
    console.log('accountLogin response', response);
    return response;
});

const GET_USER = createAsyncThunk('account/user/get', async () => {
    // debugger; // eslint-disable-line no-debugger
    console.log('getUser');
    const response = await getUserAPI();
    console.log('getUser response', response);
    return response;
});

const UPDATE_USER = createAsyncThunk('account/user/update', async ({ data = {}, file }) => {
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type, isPrivate: false });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    console.log('update user');
    const response = await updateUserAPI({ data });
    console.log('updateUser response', response);
    return response;
});

const UPDATE_USER_PASSWORD = createAsyncThunk('user/password', async ({ oldPassword, newPassword }) => {
    console.log('UPDATE_USER_PASSWORD');
    const response = await updateUserPasswordAPI({ oldPassword, newPassword });
    console.log('updateUserPassword response', response);
    return response;
});

const AUTH_USER_LINKEDIN = createAsyncThunk('account/user/linkedin/auth', async ({ code }) => {
    console.log('auth user linkedin');
    const response = await authUserLinkedinAPI({ code });
    console.log('auth user linkedin response', response);
    return response;
});

const ADD_PAYMENT_METHOD = createAsyncThunk('account/pm', async ({ paymentMethod, billingCountry }) => {
    console.log('ADD_PAYMENT_METHOD');
    const data = { paymentMethod, billingCountry };
    const response = await addPaymentMethodAPI({ data });
    console.log('ADD_PAYMENT_METHOD response', response);
    return response;
});

const GET_INVOICE_LIST = createAsyncThunk('account/payments/list', async () => {
    console.log('GET_PAYMENT_LIST');
    const response = await getInvoiceListAPI();
    console.log('GET_PAYMENT_LIST response', response);
    return response;
});

const GET_PRICES = createAsyncThunk('account/prices', async () => {
    console.log('GET_PRICES');
    const response = await getPricesAPI();
    console.log('GET_PRICES response', response);
    return response;
});

// const GET_ACCOUNT = createAsyncThunk('account/get', async () => {
//     // debugger; // eslint-disable-line no-debugger
//     console.log('getAccount');
//     const response = await getAccountAPI();
//     console.log('getAccount response', response);
//     return response;
// });

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        LOGIN(state, action) {
            // debugger; // eslint-disable-line no-debugger
            state.isLoggedIn = true;
            state.user = { ...state.user, ...action.payload };
            // state.isAdmin = action.payload.isAdmin;
            // state.user = action.payload.userData;
            // state.fee = action.payload.feeData;
            // state.org = {};
            // state.orgDataLoaded = false;
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.isInitialized = false;
            state.invoicesInitialized = false;
            state.invoices = [];
            state.pricesInitialized = false;
            state.prices = [];
            state.org = {};
            if (state.defaultAwardID) {
                delete state.defaultAwardID;
            }
            const keepKeys = ['givenName', 'familyName', 'email'];
            Object.keys(state.user).forEach((key) => keepKeys.includes(key) || delete state.user[key]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_USER.fulfilled, (state, action) => {
                console.log('getUser.fulfilled');
                console.log('action', action);
                state.isInitialized = true;
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(ACCOUNT_LOGIN.fulfilled, (state, action) => {
                console.log('accountLogin.fulfilled');
                console.log('action', action);
                const userData = action.payload.user;
                const orgData = action.payload.org;
                state.isInitialized = true;
                state.user = { ...state.user, ...userData };
                state.org = { ...state.org, ...orgData };
            })
            .addCase(UPDATE_USER.fulfilled, (state, action) => {
                console.log('UPDATE_USER.fulfilled');
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(AUTH_USER_LINKEDIN.fulfilled, (state, action) => {
                console.log('AUTH_USER_LINKEDIN.fulfilled');
                state.user = { ...state.user, ...action.payload.user };
            })
            .addCase(GET_INVOICE_LIST.fulfilled, (state, action) => {
                console.log('GET_INVOICE_LIST.fulfilled');
                state.invoicesInitialized = true;
                state.invoices = [...action.payload];
            })
            .addCase(GET_PRICES.fulfilled, (state, action) => {
                console.log('GET_PRICES.fulfilled');
                state.pricesInitialized = true;
                state.prices = [...action.payload];
            })
            .addCase(ADD_PAYMENT_METHOD.fulfilled, (state, action) => {
                console.log('ADD_PAYMENT_METHOD.fulfilled');
                const orgData = action.payload.org;
                state.org = { ...state.org, ...orgData };
            });
    }
});

const loginStatus = (state) => state?.account?.isLoggedIn;
const userData = (state) => state?.account?.user;
const orgData = (state) => state?.account?.org;
const invoices = (state) => state?.account?.invoices || [];
const invoicesInitialized = (state) => state?.account?.invoicesInitialized;
const prices = (state) => state?.account?.prices || [];
const pricesInitialized = (state) => state?.account?.pricesInitialized;
const { LOGIN, LOGOUT } = accountSlice.actions;

export {
    LOGIN,
    LOGOUT,
    GET_USER,
    UPDATE_USER,
    ACCOUNT_LOGIN,
    ADD_PAYMENT_METHOD,
    GET_INVOICE_LIST,
    UPDATE_USER_PASSWORD,
    GET_PRICES,
    AUTH_USER_LINKEDIN,
    loginStatus,
    userData,
    orgData,
    invoices,
    invoicesInitialized,
    prices,
    pricesInitialized
};
export default accountSlice.reducer;

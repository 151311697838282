import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';

// project import
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// initial state
const initialState = {
    ...defaultConfig,
    onChangeMenuType: () => {},
    onChangePresetColor: () => {},
    onChangeLocale: () => {},
    onChangeRTL: () => {},
    onChangeContainer: () => {},
    onChangeFontFamily: () => {},
    onChangeBorderRadius: () => {},
    onChangeOutlinedField: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
    const [config, setConfig] = useLocalStorage('nyaConfig', {
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        navType: initialState.navType,
        presetColor: initialState.presetColor,
        locale: initialState.locale
    });

    const contextValue = useMemo(() => {
        const onChangeOutlinedField = (outlinedFilled) => {
            setConfig({ ...config, outlinedFilled });
        };
        const onChangeBorderRadius = (event, newValue) => {
            setConfig({ ...config, borderRadius: newValue });
        };
        const onChangeFontFamily = (fontFamily) => {
            setConfig({
                ...config,
                fontFamily
            });
        };
        const onChangeContainer = () => {
            setConfig({ ...config, container: !config.container });
        };
        const onChangeLocale = (locale) => {
            setConfig({ ...config, locale });
        };
        const onChangePresetColor = (presetColor) => {
            setConfig({ ...config, presetColor });
        };
        const onChangeMenuType = (navType) => {
            setConfig({ ...config, navType });
        };

        return {
            ...config,
            onChangePresetColor,
            onChangeLocale,
            onChangeContainer,
            onChangeOutlinedField,
            onChangeBorderRadius,
            onChangeFontFamily,
            onChangeMenuType
        };
    }, [config, setConfig]);

    return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
}

ConfigProvider.propTypes = {
    children: PropTypes.node
};

export { ConfigProvider, ConfigContext };

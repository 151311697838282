import { apiDelete, apiGet, apiPost, apiUpdate } from './apiClient';

const getPersonAPI = async () => {
    const response = await apiGet({ url: `/person` });
    return response;
};
const updatePersonAPI = async ({ data }) => {
    const response = await apiUpdate({ url: `/person`, data });
    return response;
};

const deletePersonAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/person?id=${id}` });
    return response;
};
const createPersonAPI = async ({ data }) => {
    // debugger; // eslint-disable-line no-debugger
    const response = await apiPost({ url: `/person`, data });
    return response;
};
const getPersonListAPI = async () => {
    const response = await apiGet({ url: `/person/list` });
    return response;
};

export { createPersonAPI, deletePersonAPI, getPersonAPI, getPersonListAPI, updatePersonAPI };

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MemberGuard from 'utils/route-guard/MemberGuard';
import Loadable from 'ui-component/Loadable';

// Pages
const MemberDashboard = Loadable(lazy(() => import('views/dashboard/MemberDashboard')));
const InvestmentList = Loadable(lazy(() => import('views/sample-page')));
const InvestmentDetail = Loadable(lazy(() => import('views/sample-page')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/member',
    element: (
        <MemberGuard>
            <MainLayout />
        </MemberGuard>
    ),
    children: [
        {
            path: '/member',
            element: <MemberDashboard />
        },
        {
            path: '/member/investments',
            element: <InvestmentList />
        },
        {
            path: '/member/investment',
            element: <InvestmentDetail />,
            children: [{ path: ':id', element: <InvestmentDetail /> }]
        },
        {
            path: '/member/play',
            element: <PlayPage />
        },
        {
            path: '/member/settings',
            element: <UserSettings />
        }
    ]
};

export default MainRoutes;

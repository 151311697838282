import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAssetListAPI, createAssetAPI, registerAssetAPI } from 'APIs/asset';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

// ==============================|| REDUX STORE: ASSET DATA ||============================== //

// Upload files
const uploadFile = async ({ file, name }) => {
    if (!(file instanceof File)) {
        throw new Error(`Invalid file: ${name}`);
    }
    // Get s3 upload URL
    const fileExtension = file.name.split('.').pop();
    const cleanName = name.replace(/[^a-zA-Z0-9]/g, '');
    const fileName = `${cleanName}.${fileExtension}`;
    const path = 'assets';
    const mimeType = file.type;
    const urlResponse = await getFileURLAPI({ fileName, path, mimeType, isPrivate: true });
    // Upload to s3
    const { url } = urlResponse;
    await uploadFileWithURLAPI({ url, file });
    return url.split('?')[0];
};

const CREATE_ASSET = createAsyncThunk('asset/create', async ({ data, file }) => {
    // Upload files
    const url = await uploadFile({ file, name: data.name });
    // Create Asset Record
    const response = await createAssetAPI({ data: { ...data, nyaURL: url } });
    return response;
});

const GET_ASSET_LIST = createAsyncThunk('asset/list', async () => {
    const response = await getAssetListAPI();
    return response;
});

const REGISTER_ASSET_ON_LINKEDIN = createAsyncThunk('asset/register', async ({ id }) => {
    const response = await registerAssetAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    assets: []
};

const assetSlice = createSlice({
    name: 'asset',
    initialState,
    reducers: {
        create(state, action) {
            state.assets.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.assets = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_ASSET.fulfilled, (state, action) => {
                state.assets.push(action.payload);
            })
            .addCase(REGISTER_ASSET_ON_LINKEDIN.fulfilled, (state, action) => {
                state.assets = state.assets.map((asset) => {
                    if (asset.id === action.payload.id) {
                        return { ...asset, ...action.payload };
                    }
                    return asset;
                });
            })
            .addCase(GET_ASSET_LIST.fulfilled, (state, action) => {
                state.assets = [...action.payload];
                state.initialized = true;
            });
    }
});

const { logout } = assetSlice.actions;
const initialized = (state) => state?.asset?.initialized;
const assets = (state) => state?.asset?.assets || [];

export { CREATE_ASSET, GET_ASSET_LIST, REGISTER_ASSET_ON_LINKEDIN, initialized, assets, logout };
export default assetSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    showBackdrop: false,
    backdropMessage: '',
    action: false,
    showMessage: false,
    message: undefined,
    variant: 'alert',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const showSuccessMessage = ({ state, message }) => {
    const newState = {
        ...state,
        isLoading: false,
        showMessage: !!message,
        alertSeverity: 'success',
        variant: 'alert',
        message
    };
    return newState;
};

const showLoading = ({ state, backdropMessage }) => {
    const newState = {
        ...state,
        isLoading: true,
        close: true,
        message: undefined,
        showMessage: false,
        showBackdrop: true,
        backdropMessage
    };
    return newState;
};

// ==============================|| REDUX STORE: STATUS DATA ||============================== //

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        USER_MESSAGE(state, action) {
            console.log('>>>>>USER MESSAGE');
            console.log(action);
            const { payload } = action;
            console.log(state);
            state.action = !state.action;
            state.showMessage = true;
            state.message = payload.message ? payload.message.toString() : initialState.message;
            state.alertSeverity = payload.alertSeverity ? payload.alertSeverity : initialState.alertSeverity;
            state.isLoading = false;
            state.showBackdrop = false;
        },
        CLOSE_MESSAGE(state) {
            console.log('Closing message');
            state.isLoading = false;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = undefined;
            state.showBackdrop = false;
        },
        START_LOADING(state, action) {
            state.isLoading = true;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = undefined;
            if (action.payload?.backdrop) {
                state.showBackdrop = true;
            }
        },
        END_LOADING(state) {
            state.isLoading = false;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = undefined;
            state.showBackdrop = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.includes('asset/create/pending'),
                (state) => showLoading({ state, backdropMessage: 'Uploading Asset' })
            )
            .addMatcher(
                (action) => action.type.includes('asset/register/pending'),
                (state) => showLoading({ state, backdropMessage: 'Transferring Asset to Linkedin' })
            )
            .addMatcher(
                (action) => action.type.includes('post/create/pending'),
                (state) => showLoading({ state, backdropMessage: 'Saving Post' })
            )
            .addMatcher(
                (action) => action.type.includes('post/update/pending'),
                (state) => showLoading({ state, backdropMessage: 'Saving Post' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User Password' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/admin/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User Password' })
            )
            .addMatcher(
                (action) => action.type.includes('account/user/update/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User' })
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User' })
            )
            .addMatcher(
                (action) => action.type.includes('user/lock/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('user/delete/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('user/create/pending'),
                (state) => showLoading({ state, backdropMessage: 'Creating User' })
            )
            .addMatcher(
                (action) => action.type.includes('asset/transfer/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/user/linkedin/auth/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Authenticating with LinkedIn';
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/user/update/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Your information has been successfully updated' })
            )
            .addMatcher(
                (action) => action.type.includes('account/user/linkedin/auth/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'You have been successfully authenticated by Linkedin' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Your password has been successfully updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/admin/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User password changed. Please remember to inform the user.' })
            )
            .addMatcher(
                (action) => action.type.includes('user/delete/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'The User Has Been Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'New User Created and Email Sent' })
            )
            .addMatcher(
                (action) => action.type.includes('post/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'New Post Created' })
            )
            .addMatcher(
                (action) => action.type.includes('post/update/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Post Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/lock/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User Updated' })
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    console.log('REJECTED!!!');
                    console.log(`Message: ${action?.error?.message}`);
                    // state.showMessage = true;
                    // state.message = action?.error?.message;
                    // state.alertSeverity = 'error';
                    state.isLoading = false;
                    state.showBackdrop = false;
                    // state.variant = 'alert';
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                    state.showBackdrop = false;
                    // state.showMessage = false;
                    // state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.open = false;
                    state.isLoading = true;
                    // state.showMessage = false;
                    // state.message = undefined;
                }
            );
    }
});
export const isLoading = (state) => state?.status?.isLoading;
export default statusSlice.reducer;
export const { USER_MESSAGE, START_LOADING, END_LOADING, CLOSE_MESSAGE } = statusSlice.actions;
